/* .App {
    font-family: sans-serif;
    text-align: center;
  }

  .root {
    height: "100vh"

  }

  .theme{
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items:center;
  } */

  .notification_btn{
  }

  .notification_all_read{
    float: right;
  }

  .read-icon{
    margin-left: auto;
    font-size: 20px;
    width: 35px;
    height: 35px;
    background-color: rgb(187, 228, 91);
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    
  }
  .read-icon i {
    cursor: pointer;
  }
/* 
  .pagination_left{
    font-size: 25px;
  }

  .pagination_right{
    font-size: 25px;
  } */

  .provider_center_align{
    text-align: center;
  }

  .sidebar-children{
    padding-left: 10%;
  }