body {
  font-family: Manrope, sans-serif;
}

// color themes
$primary-theme: #6d72f6;
$success-theme: #08ce8a;
$secondary_100: #efefff;
$secondary_200: #ecebff;
$secondary_300: #f2eaff;
$neutral_800: #1c1c57;
$neutral_700: #404075;
$neutral_600: #73739a;
$neutral_500: #b5b5d4;
$neutral_400: #d9d9e9;
$neutral_300: #ececf6;
$neutral_200: #f7f7fc;
$neutral_100: #ffffff;

// ************************************************************
// overriding tags and others
a {
  text-decoration: unset;
  color: unset;
}

.manage-money-provider-page {
  .ql-editor {
    min-height: 300px;
  }
}

.select-search {
  width: unset;

  &:not(.select-search--multiple) .select-search__input:hover {
    border-color: $primary-theme;
  }

  .select-search_select {
  }
}


// *************************
// *************************
// common components
.btn_primary {
  background-color: $primary-theme;
  color: $neutral_100;
  padding: 12px 15px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  min-width: 100px;

  &.btn_primary-sm {
    font-size: 14px;
    padding: 10px 15px;
  }

  &.btn-modal {
    padding: 10px;
    font-size: 13px;
    width: 140px;
    // border-radius: 10px;
  }

  &:hover {
    background-color: #5055df;
    box-shadow: 0 10px 30px 0 rgb(74 58 255 / 15%);
    color: #fff;
    transition: 0.3s;
    transform: translate3d(0px, -1px, 0.01px);
    -webkit-transform: translate3d(0px, -1px, 0.01px);
  }
  &.btn_cancel {
    background-color: #f48264;
  }
}

.btn_secondary {
  background-color: $primary-theme;
  border: none;
  color: $primary-theme;
  padding: 12px 15px;
  font-weight: 400;
  font-size: 14px;
  outline: none;
  text-align: center;
  // padding: 26px 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #ececf6;
  border-radius: 30px;
  background-color: $neutral_100;
  box-shadow: 0 3px 18px 0 rgb(74 58 255 / 11%);
  cursor: pointer;
  transition: 0.3s;
  min-width: 100px;

  &.btn_secondary-sm {
    border-radius: 30px;
    font-size: 14px;
    padding: 10px 15px;
  }

  &:hover {
    border-color: #d9d9e9;
    box-shadow: 0 10px 30px 0 rgb(74 58 255 / 15%);
    -webkit-transform: translate3d(0px, -3px, 0.01px);
    transform: translate3d(0px, -1px, 0.01px);
    color: #08ce8a;
    transition: 0.3s;
  }
}

table.table-primary_theme {
  width: 100%;
  text-align: center;
  border: 1px solid $neutral_300;
  thead {
    tr {
      td {
        padding: 5px;
        background-color: $primary-theme;
        color: $neutral_100;
        // font-weight: 700;
        font-size: 14px;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      border-top: 1px solid $neutral_300;
      white-space: nowrap;

      &:last-child {
        border-bottom: none;
      }

      td {
        padding: 5px;
        color: $neutral_600;
        font-size: 14px;
        text-align: center;

        .user_img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          overflow: hidden;
          margin: auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  &.modal-table {
    text-align: start;

    thead {
      tr {
        td {
          text-align: start;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: start;
        }
      }
    }
  }
  &.transaction-history_table {
    tbody {
      tr {
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          transition: 0.3s;
          td {
            // font-size: 15px;
            // font-weight: 600;
            color: $neutral_700;
          }
        }
      }
    }
  }
}

.action-btn_group {
  .purple-btn {
    background-color: $primary-theme;
    color: $neutral_100;
    cursor: pointer;
    display: inline-block;
    // margin-right: 10px;
    padding: 8px 13px;
    position: relative;

    &:hover {
      &::before {
        position: absolute;
        top: -40px;
        right: 0;
        padding: 6px;
        background-color: $primary-theme;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
      }
    }

    &.credit-btn {
      // &:hover {
      //   &::before {
      //     content: "Credit to user account";
      //   }
      // }

      &[disabled] {
        opacity: 0.5;

        &:hover {
          &::before {
            content: unset;
          }
        }
      }
    }

    // &.transaction-btn {
    //   &:hover {
    //     &::before {
    //       content: "Transaction History";
    //     }
    //   }
    // }
    &.money-btn {
      background-color: #f48264;
      
    }
  }

  .green-btn {
    background-color: $success-theme;
    color: $neutral_100;
    cursor: pointer;
    display: inline-block;
    margin-right: 0px;
    padding: 8px 13px;
    position: relative;

    &:hover {
      &::before {
        background-color: $success-theme;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
        color: $neutral_100;
        padding: 6px;
        position: absolute;
        right: 0;
        top: -40px;
      }
    }

    // &.edit_btn {
    //   &:hover {
    //     &::before {
    //       content: "Edit Profile";
    //     }
    //   }
    // }

    &.cancel-btn {
      background-color: red;

      // &:hover {
      //   &::before {
      //     content: "Reject";
      //     background-color: red;
      //   }
      // }

      &[disabled] {
        opacity: 0.5;

        &:hover {
          &::before {
            content: unset;
          }
        }
      }
    }
  }
}

.yellow-btn {
  
}

.page-heading {
  color: $neutral_700;
  padding-top: 10px;
  padding-bottom: 20px;
  // text-align: center;
}

.action-icon {
  i {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: $neutral_800;
      font-size: medium;
      // font-size: 32px;
    }
  }
}

.modal-icon-theme {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .modal-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: $primary-theme;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      font-size: 28px;
    }
  }
}

.icon-box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
  color: #fff;
  background-color: $primary-theme;
}

.custom-item-center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.setUserBalance {
  cursor: pointer;
  display: inline-block;
  min-width: 40px;
  &:hover{
    color: $neutral_700;
  }
}

.custom-notification-badge {
  background: unset;
  border: unset;
  position: relative;

  .notification-icon {
    color: $neutral_600;
    font-size: 20px;
    width: 35px;
    // background: $primary-theme;
    height: 35px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  .notification-count {
    position: absolute;
    top: -8px;
    right: 0;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    background: red;
    color: #fff;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    justify-content: center;
  }
}

// *****************
// common classes
.custom-cursor_pointer {
  cursor: pointer;
}
.white-space_break-words {
  white-space: break-spaces;
}
.custom-mr-10 {
  margin-right: 10px;
}
.pd_20 {
  padding: 20px;
}

.object_contain {
  object-fit: contain !important;
}

.custom-outline_none {
  outline: unset;
}

.btn-text {
  font-size: 15px;
  color: $primary-theme;
  font-weight: 700;

  a {
    &:hover {
      color: $neutral_700;
    }

    &::after {
      content: "\2192";
      font-size: 16px;
    }
  }
}

.custom-box-property {
  background-color: $neutral_100;
  border-radius: 20px;
  border: 1px solid #ececf6;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  padding: 20px;
}

.font-size-13 {
  font-size: 13px;
}
.pr_5 {
  padding-right: 5px !important;
}

.input-primary_theme {
  input {
    outline: none;
    width: 100%;
    border: 1px solid $neutral_700;
    padding: 8px 12px;
    border-radius: 7px;
    font-size: 14px;
  }
}

.select-search__select ul {
  margin-left: 0 !important;
  padding-left: 0 !important;
  width: 100%;
}
// **********************************************
// validation classes
.number-error {
  border: 1px solid red !important;
}

// *********************************
// **********************************
// header codes start here
header {
  min-height: 94px;

  .header-main {
    // position: fixed;
    // right: 0;
    // left: 0;
    // top: 0;
    .header-child {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
          color: $primary-theme;
          font-weight: 800;
          font-size: 22px;
        }

        .menu-bar {
          display: none;
        }
      }

      .menus {
        ul {
          margin: 0;
          padding: 0;

          li {
            display: inline-block;
            margin-right: 30px;
            color: $neutral_600;

            a,
            a:hover,
            a:focus,
            a:active {
              text-decoration: none;
              color: inherit;
            }

            &:last-child {
              margin-right: 0;
              color: $neutral_100;
            }
          }
        }
      }

      & .custom-d-block {
        display: block;
      }

      & .custom-d-none {
        display: none;
      }
    }
  }
}

// ****************************
// ****************************
// landing or welcome page starts here
.landing-page {
  position: relative;

  .landing-banner {
    .banner-child {
      min-height: calc(100vh - 130px);
      display: flex;
      .row {
        height: inherit;

        .banner-left {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;

          h1 {
            font-weight: 800;
            font-size: 58px;
            margin-bottom: 20px;
            color: $neutral_800;
            line-height: 1;
          }

          p {
            color: $neutral_600;
            margin-bottom: 32px;
          }
        }

        .banner-right {
          width: 100%;
          height: 400px;
          position: sticky;
          top: calc(50% - 200px);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .banner-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;

      img {
        width: 68%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// ********************
// top navbar starts here
.topbar {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  // padding: 10px;
  width: 100%;

  .toggle {
    align-items: center;
    color: $neutral_600;
    cursor: pointer;
    display: flex;
    font-size: 22px;
    height: 60px;
    justify-content: center;
    width: 60px;

    i {
      background: white;
      padding: 7px 10px;
      border-radius: 8px;
    }
  }

  .logo {
    color: $primary-theme;
    font-weight: 800;
    font-size: 22px;
  }

  .search-box {
    position: relative;
    width: 300px;
    margin: 0 10px;

    label {
      width: 100%;
      position: relative;

      input {
        border-radius: 40px;
        border: 1px solid $neutral_600;
        color: $neutral_600;
        height: 40px;
        outline: none;
        padding: 5px 20px 5px 35px;
        width: 100%;
      }

      i {
        color: $neutral_600;
        font-size: 16px;
        left: 15px;
        position: absolute;
        top: 27%;
      }
    }
  }

  .menus {
    ul {
      margin: 0;
      padding: 0;

      li {
        color: $neutral_600;
        cursor: pointer;
        display: inline-block;
        margin-right: 20px;

        a,
        a:hover,
        a:focus,
        a:active {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }

  .user-box {
    position: relative;
    cursor: pointer;
    z-index: 2;
    .user-img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-name {
      color: $neutral_700;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
    }

    .user-menus {
      background-color: $neutral_100;
      border-radius: 5px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.5);
      cursor: auto;
      position: absolute;
      right: 10px;
      top: calc(100% + 5px);
      width: 240px;
      z-index: 999;

      &::before {
        border-bottom: 5px solid $neutral_100;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        content: "";
        height: 0;
        left: 85%;
        position: relative;
        top: -25px;
        width: 0;
      }

      ul {
        // padding: 0;
        // padding: 20px;
        margin: 0;
        padding-top: 0px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-left: 20px;

        li {
          list-style-type: none;
          margin-bottom: 10px;
          font-size: 15px;
          
          a,
          .logout-button {
            text-decoration: none;
            color: $primary-theme;
            cursor: pointer;

            i {
              margin-right: 10px;
            }

            &:hover {
              transition: 0.4s;
              font-weight: 700;
              // font-size: 14px;
            }
          }
        }

        .setting_dropdown {
          .setting_dropdown-btn {
            color: $primary-theme;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            // align-items: baseline;
            i {
              margin-right: 13px;
              font-size: 14px;
            }
          }

          .child_list {
            padding: 0;
            padding-left: 40px;
            height: 0;
            overflow: hidden;
            transition: 0.5s;

            li {
              font-size: 13px;
              margin-bottom: 3px;
              margin-top: 5px;
              transition: .3s;
              a {
                &:hover {
                  font-weight: 700;
                }
              }
            }

            &.show {
              // min-height: 48px;
              height: 25px;
            }

            &:hover {
              font-weight: 500;
              // color: $neutral_700;
            }
          }
        }
      }
    }
  }
}

.page-layout {
  min-height: 100vh;
  position: relative;
  // overflow-x: hidden;

  .right-side {
    width: calc(100% - 280px);
    margin-left: 280px;
    transition: 0.5s;

    &.jsActive {
      width: calc(100% - 70px);
      margin-left: 70px;
    }

    .topbar {
      padding-left: 20px;
      padding-right: 24px;
      .logo {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .main-content {
      padding: 24px;
      min-height: calc(100vh - 128px);
    }
  }
}

.side-navigation {
  background-color: $primary-theme;
  border-left: 4px solid $primary-theme;
  direction: ltr;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  transition: 0.5s;
  width: 280px;

  &.jsActive {
    width: 85px;

    ul {
      li {
        list-style-type: none;
        .parent {
          .icon {
            width: 80px;
          }

          .title {
            display: none;
          }
        }
      }
    }
  }

  ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin-top: 80px;
    padding-top: 30px;
    overflow-y: scroll;
    height: calc(100vh - 100px);
    padding-bottom: 30px;
    direction: rtl;
    padding-left: 8px;

    &::-webkit-scrollbar {
      width: 5px;
      visibility: hidden;
      margin-left: -20px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }

    &:hover {
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(165, 164, 164);
        border-radius: 10px;
      }
    }
    .submenu-parent ~ .active {
      li {
        height: 50px ;
        overflow: initial ;
      }
    }

    li {
      position: relative;
      z-index: 111;
      list-style-type: none;
      &:hover {
        .parent {
          color: white;
        }
      }

      .parent {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.7);
        direction: ltr;

        .icon {
          position: relative;
          display: block;
          min-width: 45px;
          font-size: 20px;
          text-align: center;
        }

        .title {
          font-size: 13px;
          font-weight: 700;
          text-align: left;
          padding-right: 10px;
          // text-transform: capitalize;

          .btn-settings {
            margin-left: 3px;
            cursor: pointer;

            &:hover {
              color: #fff;
              font-size: 18px;
            }
          }
        }
      }
    }

    .hide-menus {
      transition: 0.5s;

      li {
        transition: 0.5s;
        height: 0px;
        overflow: hidden;
      }
    }

    .show-menus {
      transition: 0.5s;

      li {
        transition: 0.5s;
        height: 50px;
      }
    }

    .active {
      li {
        position: relative;
        width: 100%;
        list-style: none;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        background-color: white;

        .parent {
          color: $primary-theme;
          &::before,
          &::after {
            content: "";
            position: absolute;
            right: 0;
            width: 50px;
            height: 50px;
            background: transparent;
            border-radius: 0% 50% 50% 0%;
          }

          &::before {
            top: -50px;
            box-shadow: 30px 30px 0 8px white;
          }

          &::after {
            bottom: -50px;
            box-shadow: 30px -30px 0 8px white;
          }
        }
      }
    }
  }
}



.mobile-menus {
  display: none;
  justify-content: space-around;
  font-size: 14px;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-right: 4px;
  span {
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.card-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  .info-card {
    background-color: $neutral_100;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #ececf6;

    .card-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-heading {
        // font-weight: 700;
        color: $primary-theme;
        font-size: 16px;
      }
      .card-icon {
        font-size: 32px;
        color: $neutral_600;
      }
    }

    .card-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // font-size: 16px;
      color: $neutral_600;
      // font-weight: 800;
      padding: 0;

      .card-icon {
        margin-top: -30px;
        margin-bottom: -10px;
        font-size: 26px;
      }
      .card-value {
        font-size: 14px;
      }
    }

    &:hover {
      transition: 0.4s;
      background-color: $primary-theme;

      .card-top {
        .card-heading {
          color: $neutral_100;
        }
        .card-icon {
          color: $neutral_100;
        }
      }

      .card-bottom {
        color: $neutral_400;
      }
    }
  }
}

// *************************
// *************************
// profile page starts here
.profile-page,
.deposit-money-page,
.change-password_page,
.money-request_page,
.transaction-instruction_page,
.manage-money-provider-page,
.transaction-status_page,
.deposit-to-user-page,
.login-page,
.signup-page,
.forget-password,
.user-search-bar,
.transaction-status_filter {
  label {
    display: block;
    margin-bottom: 8px;
    color: $neutral_700;
    line-height: 1.333em;
    // font-weight: 600;
    margin-left: 5px;
    font-size: 15px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  input,
  textarea,
  select {
    min-height: 44px;
    margin-bottom: 0px;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    border-style: solid;
    border-width: 1px;
    border: 1px solid $neutral_300;
    border-radius: 30px;
    background-color: $neutral_100;
    box-shadow: 0 2px 8px 0 rgb(28 28 87 / 5%);
    resize: none;
    outline: unset;
    color: $neutral_700;
    font-size: 14px;

    &[type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      opacity: 0.7;
    }

    &:hover,
    &:focus {
      border-color: $primary-theme;
      box-shadow: 0 5px 14px 0 rgb(109 114 246 / 10%);
    }

    &[disabled] {
      background-color: $neutral_300;

      &:hover {
        border-color: transparent;
      }
    }
  }
  textarea {
    padding-top: 5px;
  }
  select::after {
    content: url("../assets/images/down-arrow.png");
    color: #f48264;
    right: 3px;
    top: 8px;
    height: 44px;
    padding: 9px 14px 0px 15px;
    border-left: 3px solid #f48264;
    position: absolute;
    pointer-events: none;
    background: #f9b097;
  }

  .user-uploaded-pic {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    // overflow: hidden;
    border: 3px solid #6d72f6;
    position: relative;
    background-image: url(../assets/icons/avatar2.png);
    background-size: cover;
    cursor: pointer;

    &[disabled] {
      pointer-events: none;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      border: 0;
    }

    input[type="file"] {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: 11;
      cursor: pointer;

      &[disabled] {
        pointer-events: none;
      }
    }

    i {
      position: absolute;
      top: 3%;
      right: 3%;
      z-index: 111;
      color: $neutral_100;
      padding: 5px;
      background-color: $primary-theme;
      border-radius: 50%;
    }

    &:hover {
      &::before {
        content: "Change Image";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: auto;
        display: flex;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  .current-balance {
    color: $neutral_700;

    span {
      color: $success-theme;
      font-size: 28px;
      font-weight: 700;
    }
  }
}

.transaction-page,
.changes-history-page {
  .top-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h5 {
      color: $neutral_700;
      margin-bottom: 0;
    }
  }

  .transactions,
  .changes-history {
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.transaction-instruction_page {
  height: 100%;

  h4 {
    color: $neutral_700;
    font-weight: 700;
  }

  .img-box {
    width: 100%;
    padding: 10px;
    margin: auto;
    // max-height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .instructions-list {
    margin-top: 10px;
    max-height: 230px;
    min-height: 100px;
    overflow-y: auto;
    p {
      margin-bottom: 0;
    }

    ul,
    ol {
      li {
        // color: $neutral_700;
        // margin-bottom: 15px;
        font-size: 14px;
        list-style-type: decimal-leading-zero;
        line-height: 1.8;
        // color: $neutral_600;

        &::marker {
          font-weight: 800;
        }
      }
    }
  }
}

// ******************************************
// new money provider page
.new-money-provider-page {
  position: relative;

  .btn-add-provider {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

// **********
// logo codes here
.logo {
  // padding: 20px 20px;
  font-size: 27px;
  color: #fff;
  font-weight: 800;
}

.user-list_page,
.transaction-status_page,
.new-money-providers {
  overflow-x: scroll;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

// ********
// Block toggle button
.block_btn {
  position: relative;
  z-index: 0;

  .button-cover {
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
  }

  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    top: 50%;
    width: 63px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 2px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: $neutral_500;
    transition: 0.3s ease all;
    z-index: 1;
  }

  #button-1 .knobs:before {
    content: "NO";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 27px;
    height: 27px;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-theme;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }

  #button-1 .checkbox:checked + .knobs:before {
    content: "YES";
    left: 31px;
    background-color: #f44336;
  }

  #button-1 .checkbox:checked ~ .layer {
    background-color: #fcebeb;
  }

  #button-1 .knobs,
  #button-1 .knobs:before,
  #button-1 .layer {
    transition: 0.3s ease all;
  }
}

// *********************************************
// Animated humburger
.custom_bar {
  width: 30px;
  height: 20px;
  position: relative;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: $neutral_600;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 7px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 14px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -4px;
        left: 10px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 20px;
        left: 8px;
      }
    }
  }
}

// **************************************************************************
// *************************************************************************
// Notification codes start here
.notification-card {
  padding: 15px 10px;
  display: flex;
  // align-items: center;
  border-radius: 8px;
  margin: 10px 0;

  &.warning_msg {
    border: 1px solid rgb(228, 91, 91);
    border-top: 4px solid rgb(228, 91, 91);
    background-color: rgba(241, 149, 149, 0.08);

    .notification-icon {
      background-color: rgb(228, 91, 91);
    }

    .notification-content {
      .notification-text {
        color: rgb(228, 91, 91);
      }
    }
  }

  &.success_msg {
    border: 1px solid rgb(75, 181, 67);
    border-top: 4px solid rgb(75, 181, 67);
    background-color: rgba(133, 240, 112, 0.08);

    .notification-icon {
      background-color: rgb(75, 181, 67);
    }

    .notification-content {
      .notification-text {
        color: rgb(76, 175, 80);
      }
    }
  }

  &.pending_msg {
    border: 1px solid rgb(73, 157, 226);
    border-top: 4px solid rgb(73, 157, 226);
    background-color: rgba(112, 181, 238, 0.08);

    .notification-icon {
      background-color: rgb(73, 157, 226);
    }

    .notification-content {
      .notification-text {
        color: rgb(73, 157, 226);
      }
    }
  }

  .notification-icon {
    width: 30px;
    height: 30px;
    font-size: 16px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: $neutral_100;
  }

  .notification-content {
    .notification-text {
      font-size: 16px;
      color: $neutral_700;
      // font-weight: 700;
      line-height: 1.2;
    }

    .notifiaction-time {
      margin-top: 5px;
      font-size: 11px;
      color: $neutral_600;
    }
  }
}

// ************************************************************
// Send Money Modal
.send-money-modal {
  .MuiBox-root {
    border-radius: 10px;
    padding-bottom: 20px;
  }
  .close_btn {
    color: #fff;
    cursor: pointer;
  }
  .heading-box {
    background-color: $primary-theme;
    padding: 10px 20px;
    min-height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .modal-heading {
      color: $neutral_100;
      font-size: 18px;
      margin-bottom: 0;
      .modal-subheading {
        font-size: 12px;
      }
    }
  }
  .modal-content_box {
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal_overflow {
    overflow: scroll;

    &::-webkit-scrollbar {
      // width: 2px;
      height: 2px;
      visibility: hidden;
      margin-left: -20px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $neutral_500;
      border-radius: 10px;
    }
  }
  .heading_text {
    font-weight: 700;
    color: $neutral_600;
    font-size: 12px;
  }

  .value_text {
    color: $neutral_600;
    font-size: 14px;
    overflow-wrap: anywhere;

    .input-box {
      input,
      textarea {
        outline: unset;
        color: $neutral_600;
        border-radius: 8px;
        padding: 3px 5px;
        font-size: 15px;
        border: 1px solid $neutral_600;
        width: 100%;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}

// ***********************************************************************
// user details card
.user-details {
  min-height: 210px;

  .user-details-name {
    // display: flex;
    // align-items: flex-start;
    // gap: 15px;
    text-align: center;

    .user-icon {
      font-size: 38px;
      color: $neutral_600;
    }

    .user-about {
      line-height: 1.1;

      .user-id {
        margin-bottom: 0;
        font-weight: 700;
        color: $neutral_700;
      }

      .user-name {
        color: $neutral_700;
        font-weight: 700;
      }

      .user-earning {
        margin-top: 10px;
        color: $neutral_600;
        font-size: 15px;
        overflow-wrap: anywhere;
      }
    }
  }
}

// ***********************************************************************
// user current balance card
.current-balance-card {
  min-height: 210px;

  .current-balance {
    text-align: center;

    .balance-value {
      color: $success-theme;
      font-weight: 700;
      margin-bottom: unset;
      overflow-wrap: anywhere;
    }

    .balance-heading {
      color: $neutral_500;
    }

    .inflation-info {
      font-size: 14px;
      font-weight: 700;
      color: $neutral_600;
    }
  }
}

// *************************************************************************
// user changes hisory made by admin --> modal
.changes-history-modal {
  ul {
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 1.4;
  }
}

// *******************************************************************************
// login page starts here
.login-page,
.signup-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-page_box,
  .signup-page_box {
    // height: calc(100vh - 50px);
    min-height: 550px;
    background-color: $neutral_100;
    border-radius: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #ececf6;
    align-items: center;
    justify-content: center;

    .image-box {
      width: 100%;
      height: 550px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    .right-box {
      padding: 15px;

      .final-checkbox {
        display: flex;
        gap: 8px;
        margin: 15px 0px;

        .final-text {
          font-size: 15px;
          color: $neutral_700;

          span {
            color: $neutral_700;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .account-confirm-box {
        font-size: 14px;
        color: $neutral_600;

        a {
          color: $primary-theme;
          margin-left: 5px;
          // font-size: 16px;
          font-weight: 800;

          &:hover {
            color: $neutral_700;
          }
        }
      }

      .forget-password {
        font-size: 14px;
        color: $neutral_600;
      }
    }
  }
}

// *******************************************
// mobile number input box
.mobile-number-input-box {
  .mobile-number-input {
    display: flex;

    .country-code {
      width: 80px;
      padding-left: 10px;
      padding-right: 5px;
    }
  }
}

/*=============================================
=            pagination          =
=============================================*/
.paginate_theme {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  font-size: 13px;
  color: $neutral_800;
  font-weight: 700;

  .prev_btn {
    &::before {
      content: "\290C";
      padding-right: 5px;
    }
  }

  .next_btn {
    &::after {
      content: "\290D";
      padding-left: 5px;
    }
  }
}

/*=====  End of pagination codes ======*/

/*=============================================
=            PAGE FOOTER            =
=============================================*/

.footer-area {
  background-color: #efefef;
  display: flex;
  justify-content: center;
  column-gap: 25px;
  row-gap: 10px;
  flex-wrap: wrap;
  padding: 15px 20px;
  font-size: 12px;

  p,
  a {
    margin: 0;
    color: $neutral_600;
  }
  a {
    &:hover {
      color: $neutral_700;
    }
  }
}

/*=====  End of PAGE FOOTER  ======*/

/*=============================================
  =            modal fixing            =
  =============================================*/

.account_modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.1); /* Black w/ opacity */
}

/*=====  End of modal fixing  ======*/

/*=============================================
  =            custom-language selector            =
  =============================================*/
.custom-language-menu {
  min-width: 0 !important;
  margin: 0 !important;
  // margin-right: 10px !important;
  label {
    z-index: 0 !important;
    top: 8px !important;
    left: -8px !important;
    max-width: unset !important;
    svg.bi.bi-globe {
      width: 32px;
      height: 32px;
    }
  }
  .custom-language-menu_select {
    fieldset {
      border: 0;
      padding: 0;
      width: 0;
      height: 0;
    }
    .MuiSelect-select {
      padding: 0;
      padding-right: 0 !important;
      // height: 0;
      // min-height: 0;
      width: 30px;
      // display: none;
      opacity: 0 !important;
    }
    .MuiSelect-icon {
      display: none !important;
    }
  }
}
.custom-language-menu_options {
  font-size: 13px !important;
  min-height: 30px !important;
}

/*=====  End of custom-language selector  ======*/

/*=============================================
=            customization of search and select user            =
=============================================*/

.user-search-bar {
  .select-search {
    .select-search__value {
      z-index: 0;
      &::after {
        border-right: 1px solid $neutral_500 !important;
        border-bottom: 1px solid $neutral_500 !important;
        width: 8px !important;
        height: 8px !important;
        top: calc(50% - 7px) !important;
      }
    }
    &.has-focus {
      .select-search__value {
        &::after {
          display: none;
        }
      }
    }
  }
}

/*=====  End of customization of search and select user  ======*/

/*=============================================
=            TRANSACTION STATUS FILTER            =
=============================================*/
.transaction-status_filter {
  display: flex;
  align-items: end;
  gap: 10px;
  label {
    white-space: nowrap;
  }
  select {
    min-height: 38px;
  }
}

/*=====  End of TRANSACTION STATUS FILTER  ======*/

/*=============================================
=            loader styling            =
=============================================*/

.overlayer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 100vh;
  width: 100vw;
  // background: white;
  overflow: hidden;
  z-index: 9999999;
  .overlayer2 {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background: rgba(0, 0, 0, 0.6);
  }
}

/*=====  End of loader styling  ======*/


/*=============================================
=            user account balance box            =
=============================================*/

.user-balance-box {
  position: relative;
  .edit-balance-btn {
    position: absolute;
    right: 15px;
    top: 10px;
    // bottom: 0;
    margin: auto;
    cursor: pointer;
    font-size: 18px;
    color: $neutral_700;
  }
}


/*=====  End of user account balance box  ======*/



/*=============================================
=            ALL THE RESPONSIVENESS            =
=============================================*/

@media screen and (max-width: 991px) {
  .page-layout {
    .right-side {
      width: 100%;
      margin-left: 0;

      &.jsActive {
        left: 280px;
      }
    }
  }

  .card-box {
    grid-template-columns: 1fr 1fr;
  }

  .side-navigation {
    left: -280px;

    &.jsActive {
      left: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  header {
    .header-main {
      background-color: $neutral_100;

      .header-child {
        display: block;
        text-align: center;
        height: 100%;

        .logo-bar {
          .menu-bar {
            display: block;
          }
        }

        .menus {
          display: none;

          ul {
            height: 100vh;
            min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 25px;

            li {
              display: block;
              margin-right: 0;
            }
          }
        }

        &.d-none {
          ul {
            height: auto;
            min-height: auto;
          }
        }

        & .custom-d-block {
          display: block;
        }

        & .custom-d-none {
          display: none;
        }
      }
    }
  }

  .landing-page {
    .landing-banner {
      // height: calc(100vh - 94px);

      .banner-child {
        .row {
          .banner-left {
            h1 {
              font-size: 32px;
            }
          }

          .banner-right {
            display: none;
          }
        }
      }

      .banner-bg {
        img {
          width: 68%;
          height: 100%;
          object-fit: cover;
          display: none;
        }
      }
    }
  }

  .card-box {
    grid-template-columns: 1fr 1fr;
  }
  .transaction-instruction_page {
    .instructions-list{
      max-height: unset;
      overflow-y: unset;
    }
  }
}

@media screen and (max-width: 575px) {
  .side-navigation {
    width: 100%;
    left: -100%;
    z-index: 9;

    &.jsActive {
      left: 0;
      width: 100%;

      ul {
        li {
          .parent {
            width: 100%;

            .title {
              display: block;
            }
          }
        }
      }
    }
    ul {
      padding-right: 16px;
      margin-top: 115px;
      height: calc(100vh - 115px);
      li {
        .parent {
          width: 100%;

          .title {
            display: block;
          }
        }
      }
      .active {
        li {
          border-radius: 30px;
          .parent {
            &::before,
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
  .mobile-menus {
    display: flex;
  }
  .menus {
    display: none;
  }

  .page-layout {
    .right-side {
      &.jsActive {
        margin-left: calc(100% - 70px);
        height: 50vh;
        overflow: hidden;
        width: calc(0% + 52px);
      }

      .topbar {
        padding-left: 5px;
        padding-right: 15px;
        .logo {
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      .main-content {
        padding: 15px;
      }
    }
  }

  .topbar {
    .toggle {
      z-index: 10;
    }
  }

  .custom-box-property {
    background-color: $neutral_100;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #ececf6;
  }

  .custom_bar {
    &.open {
      span {
        &:nth-child(1) {
          background-color: #fff;
        }

        &:nth-child(3) {
          background-color: #fff;
        }
      }
    }
  }

  .card-box {
    gap: 15px 10px;

    .info-card {
      background-color: $neutral_100;
      padding: 10px 15px;
      border-radius: 15px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
      border: 1px solid #ececf6;

      .card-bottom {
        .card-icon {
          display: none;
        }
      }
    }
  }
}

/*=====  End of ALL THE RESPONSIVENESS  ======*/

.quill-editor {
  white-space: pre-wrap;
}


/*----------  overriding css in firefox starts  ----------*/
@-moz-document url-prefix() {
  select {
    padding-top: 12px;
    padding-bottom: 10px;
  }
  input[type="number"] {
    appearance: textfield;
  }
  .pr_5 {
    padding-right: 20px !important;
  }
}
/*----------  overriding css in firefox ends  ----------*/
